.topBanner {
  width: 100%;
  height: 200px;
  background-color: var(--color-primary);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.bannerTitle {
  font-size: 26px;
  color: var(--color-white);
  font-weight: 700;
  margin-left: 15%;
}

.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.cardContainer {
  border-radius: 10px;
  padding: 15px;
  display: flex;
  width: 1300px;
  gap: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;
}

.monthSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
}

.monthText {
  font-size: 30px;
  font-weight: bold;
  transform: rotate(-90deg);
  text-align: center;
}

.winnersContainer {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: center;
}

.winnerCard {
  flex: 1 1 250px;
  max-width: 300px;
  min-width: 250px;
}

.imageContainer {
  width: 100%;
  padding-bottom: 20px;
}

.winnerImage {
  width: 100%;
  height: 250px;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.winnerDetails {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.yearText {
  color: #001c45;
  font-weight: bold;
  font-size: 20px;
}

.nameText {
  font-size: 20px;
  font-weight: bold;
  padding: 0 10px;
}

.linkContainer {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.hackerRankLink {
  background-color: #001c45;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  display: inline-block;
}
