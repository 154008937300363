.app{
  width: 100vw;
}

#header {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
}
/* Topbar Styles */
#topbar {
	width: 100%;
	/* height: 60px; */
	height: 15px;
	background-color: var(--color-primary);
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0 25px;
}
#dropdown {
	box-sizing: border-box;
	width: 220px;
	position: relative;
}
#dropdownDisplayInfo {
	background-color: #DE3163;
	color: var(--color-white);
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 3px;
	padding: 8px 10px;
	cursor: pointer;
}
#dropdownMenu {
	list-style-type: none;
	padding: 8px;
	background-color: var(--color-dark-oil);
	border-radius: 0 0 3px 3px;
	color: var(--color-ghost-white);
	position: absolute;
	top: 35px;
	right: 0;
	width: 100%;
	opacity: 0;
	display: none;
	transition: 0.2s;
	z-index: 100;
}
#dropdownItem {
	padding: 10px;
	margin: 3px 0;
	border-radius: 3px;
	color: var(--color-dark-oil);
}
#dropdownItemLink {
	display: inline-block;
	color: var(--color-ghost-white);
	text-decoration: none;
	width: 100%;
}
#dropdownItemLink:hover {
	text-decoration: underline;
	text-underline-offset: 3px;
}
.dropdownMenuOpen {
	display: block !important;
	opacity: 1 !important;
}
/* Navbar Styles */
#navbar {
	background-color: var(--color-white);
	width: 100%;
	height: 75px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	padding: 0 15px;
	position: relative;
	margin-top: 0px;
	transition: margin-top 0.3s ease-in-out, width 0.3s ease-in-out;
}

#navbarScrolled {
	background-color: var(--color-white);
	backdrop-filter: blur(10px);
	width: 95%;
	height: 75px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	padding: 0 15px;
	position: fixed;
	z-index: 1000;
	margin-top: 70px;
	border-radius: 7px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	transition: margin-top 0.3s ease-in-out, width 0.3s ease-in-out, border-radius 0.3s ease-in-out,box-shadow 0.3s ease-in-out,background-color 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out;
}/*maaz*/

#logoContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 25px;
}
#logo {
	height: 65px;
	margin: 5px 0;
}
.menus {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	list-style: none;
	margin-left: 200px;
}

.menu-items {
	position: relative;
	font-size: 18px;
}

.menu-items a {
	display: block;
	font-size: inherit;
	color: inherit;
	text-decoration: none;
}

.menu-items button {
	display: flex;
	align-items: center;
	color: inherit;
	font-size: inherit;
	border: none;
	background-color: transparent;
	cursor: pointer;
	width: 100%;
}

.navbar-down-arrow {
	font-size: 14px;
	margin-left: 5px;
}

button span {
	margin-left: 3px;
}

.menu-items>a,
.menu-items button {
	text-align: left;
	padding: 13px 17px;
}

.menu-items a:hover,
.menu-items button:hover {
	border-radius: 3px;
	background-color: #f2f2f2;
}

.arrow::after {
	content: "";
	display: inline-block;
	margin-left: 0.28em;
	vertical-align: 0.09em;
	border-top: 0.42em solid;
	border-right: 0.32em solid transparent;
	border-left: 0.32em solid transparent;
}

.dropdown {
	position: absolute;
	right: 0;
	left: auto;
	box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
		0 4px 6px -2px rgba(71, 63, 79, 0.16);
	font-size: 0.875rem;
	z-index: 9999;
	min-width: 10rem;
	padding: 0.5rem 0;
	list-style: none;
	background-color: #fff;
	border-radius: 0.5rem;
	display: none;
}

.dropdown.show {
	display: block;
}

.dropdown .dropdown-submenu {
	position: absolute;
	left: 100%;
	top: -7px;
}
/* Nested Dropdown - DAPC */
.nested-dropdown-container {
    position: relative;
}

.nested-dropdown {
    position: absolute;
    top: 0;
    left: 100%; /* Positions submenu to the right */
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
                0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    padding: 0.5rem 0;
    list-style: none;
    width: 200px;
    display: none;
    z-index: 9999;
}


.nested-dropdown-container:hover > .nested-dropdown {
    display: block; /* Shows the submenu when hovering */
}

.nested-dropdown-btn {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
}

/* content */

.content {
	max-width: 1200px;
	margin: 0 auto;
	padding: 3rem 20px;
}

.content h2 {
	margin-bottom: 1rem;
}

.content a {
	color: #cc3852;
	margin-right: 10px;
}

.hamburger {
	font-size: 30px;
	display: none;
	margin-top: 5px;
}

@media screen and (max-width: 1024px) {
	#navbar {
		justify-content: space-between;
		margin: 0 15px;
	}

	#navbarScrolled { /* lalit */
		justify-content: space-between; /* lalit*/
	}
	#logoContainer {
		margin: 0;
	}
	.hamburger {
		display: block;
	}
	.menus {
		display: none;
	}
	.menus-open {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		margin: 0;
		position: absolute;
		top: 85px;
		left: 0;
		width: 100%;
		background-color: var(--color-white);
		border-bottom: 1px solid #787878;
		z-index: 50;
		border-radius: 7px;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
		transition: opacity 0.3s ease, box-shadow 0.3s ease;
	}/*maaz*/

	.menu-items {
		width: 100%;
	}
	.dropdown {
		left: 10px;
		right: auto;
	}
}

/* Footer Styles */
#footer{
  padding: 32px 80px 16px;
  background-color: var(--color-primary);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
}

#footer-head{
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
  border-bottom: 2px solid white;
}

#footer-head-logo > img{
  height: 100px;
}

#footer-head-socials{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#footer-head-socials > i{
  font-size: 2rem;
  color: var(--color-primary);
  background-color: var(--color-white);
  border-radius: 5px;
  padding: 1rem;
  margin-left: 1rem;
  cursor: pointer;
}

#footer-main {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#footer-info-left{
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
}

#footer-info-title{
  font-size: 28px;
  padding-bottom: 5px;
  font-weight: 700;
}

#footer-college-title {
  font-size: 24px;
  padding-bottom: 5px;
  font-weight: 600;
}

.footer-info-content {
  padding-bottom: 5px;
  font-size: 17px;
}

#footer-info-right {
  margin-top: 2rem;
}

.visitorsContainer {
  display: inline-block;
}

.visitors {
  padding: 5px;
  display: flex;
  align-items: center;
}

.visitorsHeading {
  color: #FFFFFF;
  font-size: 1.5rem;
}

#block {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: var(--color-white);
  color: var(--color-primary);
  margin: 3px;
  width: 35px;
  height: 35px;
  font-size: 25px;
  text-align: center;
  font-weight: 800;
  border-radius: 5px;
}

#footer-copyright{
  color: white;
  margin-top: 2rem;
  font-size: 17px;
}


@media screen and (max-width: 769px) {
  
}

/* For small devices */
@media screen and (max-width: 992px){
  .App{
    width: 100%;  /*lalit*/
  }

  #footer{
    padding: 2rem;
  }
  
  #footer-head-logo > img{
    height: 80px;
  }

  
  #footer-head-socials > i{
    padding: .4rem;
    font-size: 1.3rem;
    margin: 5px 10px;
  }

  #footer-main {
    display: flex;
    flex-direction: column;
  }

  #footer-info-right {
    margin-top: 1rem;
  }

  .visitorsHeading {
    font-size: 1.2rem;
    margin-right: 5px;
  }

  #block {
    width: 25px;
    height: 25px;
    font-size: 20px;
  }
  
  #footer-info{
    margin-top: 1rem;
    font-size: .8rem;
  }
  
  #footer-info-title{
    font-size: 1.5rem;
    padding: 0 0 15px;
  }

  #footer-college-title {
    font-size: 1.1rem;
    padding: 0 0 15px;
  }

  .footer-info-content {
    font-size: 0.9rem;
  }

  #footer-copyright {
    text-align: center;
  }
}

@media screen and (max-width: 1400px) {

}