#aiml, #ds{
    padding: var(--main-padding);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.row{
    width: 100%;
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr 80%;
    grid-auto-rows: max-content;
    align-items: center;
}

.heading{
    font-size: 2.5rem;
    height: 100%;
    background-color: var(--bg-dark);
    color: white;
    text-align: center;
}

.listitem{
    font-size: 1.3rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-medium);
}

.listitem a{
    text-decoration: none;
    margin-left: .5rem;
}

.topBanner {
    width: 100%;
    height: 200px;
    background-color: var(--color-primary);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bannerTitle {
    font-size: 26px;
    color: var(--color-white);
    font-weight: 700;
    margin-left: 15%;
}

.outerContainer {
    max-width: 1140px;
    width: 80%;
    /* margin: auto; */
    margin-left: 5px;
    display: grid;
    grid-template-areas: 'left right';
    grid-template-columns: 1fr 2fr;
    /* grid-auto-flow: row; */
    grid-gap: 2rem;
    padding: 50px 0;
}

.gridLeft {
    text-align: center;
}
.gridLeft > button {
    display: block;
    margin: 5px;
    width: 150px;
    height: 50px;
}
.gridLeft > button > a {
    text-decoration: none;
    color: #000;
}
.gridRight {
    /* padding: 0 30px; */
}
#data {
    width: 100%;
    min-height: 300px;
}
.academicLinks {
    list-style-position: inside;
    list-style-type: none;
}
.academicLinks > li {
    padding: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.academicLinks > li::before {
    color: transparent;
    font-size: 1px;
    content: " ";
    margin-left: -1.3em;
    margin-right: 15px;
    padding: 10px;
    background-color: var(--color-dark-oil);
    -webkit-mask-image: url("../../assets/academics-list-bullet.svg");
    -webkit-mask-size: calc(100%);
}
.academicLinks > li > a {
    color: var(--color-accent);
    text-underline-offset: 5px;
}
.academicLinks>li>a:hover {
    font-weight: 700;
}
.vmCard {
    width: 100%;
    padding: 15px 25px;
    margin: 0 0 30px;
    border-left: 8px solid var(--color-primary);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.vmTitle {
    color: var(--color-primary);
    font-size: 24px;
    margin-bottom: 15px;
}
.vmContent {
    font-size: 16px;
    text-align: justify;
    /* margin-bottom: 10px; */
}
.vmTable {
    width: 100%;
    margin: 20px 0 10px;
    border-collapse: collapse;
}
.vmTableTH {
    width: 15%;
    vertical-align: top;
    text-align: center;
    padding: 5px;
}
.vmTableTD {
    width: 85%;
    padding: 5px;
}

/* For small devices */
@media screen and (max-width: 768px) {
    .outerContainer {
        width: 100%;
        margin: auto;
        grid-template-areas: none;
        grid-template-columns: none;
    }
    .gridRight {
        width: 90%;
        /* margin: 0 5%; */
        padding: 15px;
    }
}
@media screen and (max-width: 600px) {
    .topBanner {
        height: 200px;
        justify-content: center;
        text-align: center;
    }

    .bannerTitle {
        font-size: 22px;
        padding: 0 15px;
        margin-left: 0;
    }
    .gridRight {
        padding: 0 5px;
    }
}